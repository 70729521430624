// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import Honeybadger from '@honeybadger-io/js';

import { HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import ErrorComponent from 'common/components/ErrorComponent.js';

type AnyProps = {| [string]: any |};

type ComponentMap = any;
type LazyComponentMap = any;

export function exposeComponents(mountableComponents: ComponentMap) {
  window.Venuu.REACT_COMPONENTS = {
    ...window.Venuu.REACT_COMPONENTS,
    ...mountableComponents
  };
}

export function exposeLazyComponents(mountableComponents: LazyComponentMap) {
  window.Venuu.REACT_LAZY_COMPONENTS = {
    ...window.Venuu.REACT_LAZY_COMPONENTS,
    ...mountableComponents
  };
}

export function createElement(
  component: React.ComponentType<AnyProps>,
  props: AnyProps
): React.Node {
  // $FlowFixMe[not-a-function] -- Suppressed during Flow upgrade to 0.241.0. Delete this comment to view the error.
  return React.createElement(component, props);
}

const mountComponentFromNode = (node: HTMLElement) => {
  const componentName = node.getAttribute('data-react-class');
  const componentId = node.getAttribute('data-component-id');
  if (!componentName || !componentId) {
    throw new Error("Can't mount react node");
  }
  const props = window.Venuu.REACT_PROPS[`${componentName}:${componentId}`];
  mountComponent(componentName, props, node);
};

function mountComponent(
  componentName: string,
  props: AnyProps,
  node: HTMLElement
) {
  const normalComponent =
    window.Venuu.REACT_COMPONENTS &&
    window.Venuu.REACT_COMPONENTS[componentName];
  const loadLazyComponent =
    window.Venuu.REACT_LAZY_COMPONENTS &&
    window.Venuu.REACT_LAZY_COMPONENTS[componentName];

  if (normalComponent) {
    render(normalComponent, props, node);
  } else if (loadLazyComponent) {
    loadLazyComponent()
      .then(component => {
        render(component, props, node);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  } else {
    throw new Error(
      `Could not find mountable component ${componentName}. ` +
        'You need to expose it through REACT_COMPONENTS or REACT_LAZY_COMPONENTS.'
    );
  }
}

function render(component: any, props: AnyProps, node: HTMLElement) {
  try {
    Honeybadger.setContext({ component: component.name, props });
    // $FlowFixMe[incompatible-type] createElement() has a too wide potential return type
    const element: React.Element<React.ElementType> = createElement(
      component,
      props
    );
    // TODO: Call ReactDOM.hydrate instead when the component has been server-rendered
    ReactDOM.render(
      <HoneybadgerErrorBoundary
        honeybadger={Honeybadger}
        ErrorComponent={ErrorComponent}
      >
        {element}
      </HoneybadgerErrorBoundary>,
      node
    );
  } finally {
    Honeybadger.setContext({ component: null, props: null });
  }
}

export function mountAllComponents() {
  const reactNodes = document.querySelectorAll('[data-react-class]');

  // YYyeah, document.querySelectorAll doesn't return an Array but a NodeList object. And that
  // fucker naturally doesn't support .map function, because DOM API sucks balls, so we have to
  // rely on normal for-loop to iterate the nodes.
  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList#Why_is_NodeList_not_an_Array
  for (let i = 0; i < reactNodes.length; i++) {
    mountComponentFromNode(reactNodes[i]);
  }
}
