// @flow

import type { JSONValue, JSONObject } from 'common/types';

export type ExtractValues<Shape: { ... }> = $ObjMap<
  Shape,
  <V>(extractor: (JSONValue) => V) => V
>;

export default function record<Shape: { ... }>(
  shape: Shape
): JSONObject => ExtractValues<Shape> {
  return (json: JSONObject) => {
    return Object.keys(shape).reduce<{ [empty]: any }>((result, key) => {
      result[key] = shape[key](json[key]);
      return result;
    }, {});
  };
}
